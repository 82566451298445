import axios from '@/plugins/axios';

class BillingService {
  upgradeAccount(data) {
    this.data = data;
    return axios().post('billing/upgrade', this.data)
      .then((response) => response.data)
      .catch((error) => Promise.reject(error.response.data));
  }
}

export default new BillingService();
