<template>
  <div class="content">
    <PageHeader title="Upgrade de Conta" />
    <div class="page-content container-fluid">
      <div class="row">
        <div class="col-12">
          <div class="card">
            <div class="card-header" role="button">
              Adicionar endereço da empresa
              <ul class="actions top-right">
                <li>
                  <a href="javascript:void(0)">
                    <i class="icon dripicons-chevron-down"></i>
                  </a>
                </li>
              </ul>
            </div>
            <form @submit.prevent="upgrade">
              <div class="card-body">
                <div class="row">
                  <div class="col-lg-6 col-md-6 col-sm-12">
                    <div class="row">
                      <div class="col-12">
                        <div class="form-group">
                          <label for="companyName">{{$t('adm.create.corporate-name')}}</label>
                          <input
                            type="text"
                            class="form-control"
                            id="companyName"
                            v-model="form.company_name"
                            required
                          />
                        </div>
                      </div>
                      <div class="col-lg-6 col-md-6 col-sm-6">
                        <div class="form-group">
                          <label for="billingEmail">Email de Cobrança</label>
                          <input
                            type="text"
                            class="form-control"
                            id="billingEmail"
                            v-model="form.billing_email"
                            required
                          />
                        </div>
                      </div>
                      <div class="col-lg-3 col-md-3 col-sm-6">
                        <div class="form-group">
                          <label for="tax_id">CNPJ</label>
                          <input
                            type="text"
                            class="form-control"
                            id="tax_id"
                            v-model="form.tax_id"
                            v-mask="'##.###.###/####-##'"
                            required
                            v-bind:class="{ invalid: !cnpjValid }"
                          />
                          <div class="invalid-field" v-if="!cnpjValid">
                            CNPJ inválido
                          </div>
                        </div>
                      </div>
                      <div class="col-lg-3 col-md-3 col-sm-6">
                        <div class="form-group">
                          <label for="postal_code">{{$t('generic-str.zipcode')}}</label>
                          <input
                            type="tel"
                            class="form-control"
                            id="postal_code"
                            v-model="form.postal_code"
                            v-mask="'#####-###'"
                            @keyup="searchAddress"
                            required
                          />
                          <div
                            v-if="searching"
                            class="preloader pl-xxs pls-primary"
                          >
                            <svg class="pl-circular" viewBox="25 25 50 50">
                              <circle class="plc-path" cx="50" cy="50" r="20" />
                            </svg>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div v-if="form.city" class="row">
                      <div class="col-lg-6 col-md-6 col-sm-6">
                        <div class="form-group">
                          <label for="street">{{$t('billing.prefs.lbl-address')}}</label>
                          <input
                            v-model="form.street"
                            type="text"
                            class="form-control"
                            id="street"
                            required
                          />
                        </div>
                      </div>
                      <div class="col-lg-6 col-md-6 col-sm-6">
                        <div class="form-group">
                          <label for="district">{{$t('generic-str.neighborhood')}}</label>
                          <input
                            v-model="form.district"
                            type="text"
                            class="form-control"
                            id="district"
                            required
                          />
                        </div>
                      </div>
                      <div class="col-6 col-xl-6">
                        <div class="form-group">
                          <label for="number">{{$tc('generic-str.lbl-number', 1)}}</label>
                          <input
                            v-model="form.number"
                            type="text"
                            class="form-control"
                            id="number"
                            required
                          />
                        </div>
                      </div>
                      <div class="col-6 col-xl-6">
                        <div class="form-group">
                          <label for="complement">{{$t('generic-str.complement')}}</label>
                          <input
                            v-model="form.complement"
                            type="text"
                            class="form-control"
                            id="complement"
                          />
                        </div>
                      </div>
                      <div class="col-md-8 col-lg-8 col-sm-12">
                        <div class="form-group">
                          <label for="city">{{$t('generic-str.city')}}</label>
                          <input
                            v-model="form.city"
                            type="text"
                            class="form-control"
                            id="city"
                            required
                          />
                        </div>
                      </div>
                      <div class="col-md-4 col-lg-4 col-sm-12">
                        <div class="form-group">
                          <label for="province">{{$t('generic-str.state')}}</label>
                          <ProvinceSelect :province="form.province" />
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="col-lg-6 col-md-6 col-sm-12">
                    <div class="card card-border-info">
                      <div class="card-header">Adicionar créditos</div>
                      <div class="card-body">
                        <div class="form-group">
                          <label for="companyName"
                            >Valor a adicionar à sua conta</label
                          >
                          <input
                            type="text"
                            class="form-control"
                            v-model="form.value"
                            id="companyName"
                            required
                            v-money="money"
                            v-bind:class="{ invalid: invalidValue }"
                          />
                          <div class="invalid-field" v-if="invalidValue">
                            {{ invalidValueMessage }}
                          </div>
                          <small id="emailHelp3" class="form-text text-muted"
                            >O valor mínimo de R$ 100,00 e valor máximo de R$
                            5.000,00</small
                          >
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="card-footer bg-light">
                <button
                  type="submit"
                  class="btn btn-primary"
                  :class="{
                    'qt-loader qt-loader-mini qt-loader-right': isSending,
                  }"
                  :disabled="isSending"
                >
                  Upgrade
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
// @ is an alias to /src
import axios from 'axios';
import { mask } from 'vue-the-mask';
import BillingService from '@/services/billing.service';
import Collapse from '@/directives/Collapse';
import PageHeader from '@/components/PageHeader.vue';
import ProvinceSelect from '@/components/ProvinceSelect.vue';

const { cnpj } = require('cpf-cnpj-validator');

export default {
  name: 'Billing',
  directives: {
    Collapse,
    mask,
  },
  components: {
    PageHeader,
    ProvinceSelect,
  },
  data() {
    return {
      cnpjValid: true,
      searching: false,
      isSending: false,
      invalidValue: false,
      invalidValueMessage: '',
      form: {
        tax_id: '',
        postal_code: '',
        street: '',
        number: '',
        complement: '',
        district: '',
        city: '',
        province: '',
      },
      money: {
        decimal: ',',
        thousands: '.',
        prefix: 'R$ ',
        precision: 2,
        masked: false,
      },
    };
  },
  computed: {
    account() {
      return this.$store.state.account;
    },
  },
  methods: {
    searchAddress() {
      if (this.form.postal_code.length === 9) {
        this.searching = true;
        axios
          .get(`https://viacep.com.br/ws/${this.form.postal_code}/json/`)
          .then((response) => {
            this.searching = false;
            this.form.street = response.data.logradouro;
            this.form.district = response.data.bairro;
            this.form.city = response.data.localidade;
            this.form.province = response.data.uf;
          });
      }
    },
    upgrade() {
      this.form.tax_id = this.form.tax_id.replace('.', '');
      this.form.tax_id = this.form.tax_id.replace('/', '');
      this.form.tax_id = this.form.tax_id.replace(' ', '');
      this.cnpjValid = cnpj.isValid(this.form.tax_id);
      if (this.cnpjValid) {
        this.invalidValue = false;
        const rawValue = this.form.value.replace(/[^0-9]/g, '') / 100;
        if (rawValue < 100) {
          this.invalidValue = true;
          this.invalidValueMessage = 'O valor deve ser maior do que R$ 100,00';
          return;
        }
        if (rawValue > 5000) {
          this.invalidValue = true;
          this.invalidValueMessage =
            'O valor deve ser menor do que R$ 5.000,00';
          return;
        }
        this.isSending = true;
        if (!this.invalidValue) {
          BillingService.upgradeAccount(this.form).then(
            () => {
              this.isSending = false;
              this.$toast.show({
                title: this.$t('generic-str.success'),
                content: 'Conta atualizada',
                type: 'success',
              });
              this.$router.push('/invoices');
            },
            (error) => {
              this.isSending = false;
              this.$toast.show({
                title: `${this.$t('sms.infos.filters.status.lbl-error')} ${error.code}`,
                content: error.message,
                type: 'danger',
              });
            },
          );
        }
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.form-group .preloader {
  right: 20px;
  position: absolute;
  bottom: 20px;
}
</style>
